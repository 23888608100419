export interface TicketTagRelation {
  _id: string
  ticketTagIds: Array<string>
  ticketId: string
  organizationId: string
}

export interface TicketCountAggregate {
  ticketTag: string
  count: number
}

export const useTicketTagRelationStore = defineStore(`ticket-tag-relation`, () => {
  /** @deprecated */
  const ticketTagRelations = ref<Array<TicketTagRelation>>([])
  /** @deprecated */
  const tagTicketCount = ref<Array<TicketCountAggregate>>([])

  const createTicketTagRelation = async (item: Partial<TicketTagRelation>) => {
    useCrudMethods<TicketTagRelation>("/ticket-tag-relation", ticketTagRelations).createItem(item)
  }

  const createTicketTagRelations = async (items: Array<Partial<TicketTagRelation>>) => {
    const { data } = await useSamApi<TicketTagRelation>("/ticket-tag-relation/batch-create", { method: "POST", body: JSON.stringify({ items }) })
    return data
  }

  const getTicketTagCount = async (): Promise<Array<TicketCountAggregate>> => {
    const { data } = await useSamApi<Array<TicketCountAggregate>>("/ticket-tag-relation/ticket-count")
    tagTicketCount.value = data
    return data
  }

  return {
    tagTicketCount,

    createTicketTagRelation,
    createTicketTagRelations,
    getTicketTagCount
  }
})